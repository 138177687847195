body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sign-out-btn{width: 240px; float: right;}
.login-user-top{float: left;margin-left: 80px; margin-top: 8px;}

.loader2 {
  border: 4px solid #dddddd;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin-top: 10px;
  margin-left: 70px;
  display:none;
}

.loader {
	border: 4px solid #dddddd;
	border-radius: 50%;
	border-top: 4px solid #3498db;
	width: 30px;
	height: 30px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
	margin-top: 10px;
	margin-left: 70px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 1008;
}
.loader-box{
	width: 100%;
    background-color: #020202;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1002;
    opacity: 0.5;
	display:none;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.navbar-fixed-top {
    top: 66px;
    border-width: 0 0 1px;
}
.card{border:none !important;}
.navbar-fixed-top, .navbar-fixed-bottom {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}
.tracker-pdf-data{
	visibility: hidden;
    height: 0;
	overflow: hidden;
}

.hide-for-now .answer{display: none;}

.custom-navbar-2{
	position: fixed;
    right: 0;
    z-index: 1030;
	top: 66px;
    border-width: 0 0 1px;
}

.nav .open > a {
	background-color: transparent;
}

.nav .open > a:hover {
	background-color: transparent;
}

.nav .open > a:focus {
	background-color: transparent;
}

#wrapper {
	padding-left: 0;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

#wrapper.toggled {
	padding-left: 300px;
}

#wrapper.toggled #sidebar-wrapper {
	width: 300px;
}

#wrapper.toggled #page-content-wrapper {
	margin-right: -300px;
	position: absolute;
}

#sidebar-wrapper {
	background: #f0f0f0;
	height: 100%;
	left: 220px;
	margin-left: -220px;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	width: 0;
	z-index: 1000;
	border: 2px solid #ccc;
}

.container-2{padding: 10px 25px;}

#sidebar-wrapper::-webkit-scrollbar {
	display: none;
}


#page-content-wrapper {
	padding-top: 5px;
	width: 60%;
}

#sidebar-wrapper-right{
	background: #f0f0f0;
	height: 100%;
	right: 220px;
	margin-right: -220px;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	width: 0;
	z-index: 1000;
	border: 2px solid #ccc;
}

#sidebar-wrapper-right::-webkit-scrollbar {
	display: none;
}

#wrapper.toggled-2 {
	padding-right: 440px;
}

#wrapper.toggled-2 #sidebar-wrapper-right {
	width: 440px;
}

#wrapper.toggled-2 #page-content-wrapper {
	position: absolute;
}

#wrapper.toggled-2 .fadeInRight {
    right: 454px;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.sidebar-nav {
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 0;
	width: 220px;
}

.sidebar-nav li {
	display: inline-block;
	line-height: 20px;
	position: relative;
	width: 100%;
}

.fadeInLeft, .fadeInRight{
	display: inline-block;
    width: 16px;
    height: 16px;
}



.fadeInRight.is-closed, .fadeInLeft.is-open{
	background: url('/build/layout_arrows.png') no-repeat 0 0 !important;
}

.fadeInRight.is-open, .fadeInLeft.is-closed{
	background: url('/build/layout_arrows.png') no-repeat 0 -16px !important;
}
.fadeInRight.is-closed, .fadeInLeft.is-open{
	background: url('/layout_arrows.png') no-repeat 0 0 !important;
}

.fadeInRight.is-open, .fadeInLeft.is-closed{
	background: url('/layout_arrows.png') no-repeat 0 -16px !important;
}


.mds-manager{display:none;}
.dropdown-col3{display:none;}
.mds-sheets{display:none;}
.grid-sacale input{ margin-bottom: 5px;}
.needNicon .need-icon{width:100% !important;}

.sidebar-nav li:before {
	background-color: #6bb4e7;
}

#redoInfo{margin: 10px 0px;}
.sidebar-nav li a {
	color: #000000;
	display: block;
	padding: 10px 15px 10px 30px;
	text-decoration: none;
}

.panel-btn .btn{
	line-height: 1.2 !important;
	width: 200px !important;
	margin-top: 5px;
}	

.sidebar-nav .dropdown-menu {
	background-color: #222222;
	border-radius: 0;
	border: none;
	-ms-box-shadow: none;
	box-shadow: none;
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;
}


.sidebar-nav > .sidebar-brand {
	font-size: 20px;
	height: 65px;
	line-height: 44px;
}

.fadeInRight{
	right: 20px;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	
}


.hamburger {
	background: transparent;
	border: none;
	margin-left: 15px;
	position: fixed;
	top: 68px;
	z-index: 999;
}

.hamburger:hover {
	outline: none;
}

.hamburger:focus {
	outline: none;
}

.hamburger:active {
	outline: none;
}

.hamburger.is-closed:before {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
	content: '';
	display: block;
	font-size: 14px;
	line-height: 32px;
	-ms-opacity: 0;
	opacity: 0;
	text-align: center;
	width: 100px;
}

.hamburger.is-closed:hover before {
	-webkit-transform: translate3d(-100px, 0, 0);
	transform: translate3d(-100px, 0, 0);
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
	display: block;
	-ms-opacity: 1;
	opacity: 1;
}

.hamburger.is-closed:hover .hamb-top {
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
	top: 0;
}

.hamburger.is-closed:hover .hamb-bottom {
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
	bottom: 0;
}

.hamburger.is-closed .hamb-top {
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
	top: 5px;
}

.hamburger.is-closed .hamb-middle {
	margin-top: -2px;
	top: 50%;
}

.hamburger.is-closed .hamb-bottom {
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
	bottom: 5px;
}

.hamburger.is-closed .hamb-top, .hamburger.is-closed .hamb-middle, .hamburger.is-closed .hamb-bottom, .hamburger.is-open .hamb-top, .hamburger.is-open .hamb-middle, .hamburger.is-open .hamb-bottom {
	height: 4px;
	left: 0;
	position: absolute;
	width: 100%;
}

.hamburger.is-open .hamb-top {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
	transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
	transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
	transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08), -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
	margin-top: -2px;
	top: 50%;
}

.hamburger.is-open .hamb-middle {
	display: none;
}

.hamburger.is-open .hamb-bottom {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
	transition: -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
	transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
	transition: transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08), -webkit-transform 0.2s cubic-bezier(0.73, 1, 0.28, 0.08);
	margin-top: -2px;
	top: 50%;
}

.hamburger.is-open:before {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
	content: '';
	display: block;
	font-size: 14px;
	line-height: 32px;
	-ms-opacity: 0;
	opacity: 0;
	text-align: center;
	width: 100px;
}

.hamburger.is-open:hover before {
	-webkit-transform: translate3d(-100px, 0, 0);
	transform: translate3d(-100px, 0, 0);
	-webkit-transition: all 0.35s ease-in-out;
	transition: all 0.35s ease-in-out;
	display: block;
	-ms-opacity: 1;
	opacity: 1;
}


.overlay {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000000;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 1;
}

.hamb-bottom, .hamb-middle, .hamb-top {
	background-color: black;
}

.item-fields {
  margin: 10px 0;
  margin-bottom: 80px;
}

.item-fields #field .card {
  margin-bottom: 10px;
  border: 0;
}

.item-fields #field .card .card-header {
  border: 0;
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

.item-fields #field .card .card-header .btn-header-link {
  display: block;
  text-align: left;
  color: #222;
  padding: 10px;
  font-weight: 700;
}

.item-fields #field .card .card-header .btn-header-link:after {
  content: "\f068";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

.item-fields #field .card .card-header .btn-header-link.collapsed {
}

.item-fields #field .card .card-header .btn-header-link.collapsed:after {
  content: "\f067";
}

.item-fields #field .card .collapsing {
  line-height: 30px;
}

.item-fields #field .card .collapse {
  border: 0;
}

.item-fields #field .card .collapse.show {
  line-height: 30px;
  color: #222;
}
.rdw-editor-wrapper{background: #fff;}
.btn-update-edit{float: right;}
.page-title{text-align: center;margin: 0;}
.panel-btn{text-align: center;}
.nav-content{padding: 10px 15px;width: 298px;}
.nav-content-right{padding: 10px 30px;width: 430px;}

.field-btn{height: 25px;display: block;border: none;background: no-repeat; padding: 0;}

.btn.focus, .btn:focus, .btn-header-link {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.type-q2{width: 94% !important; float: left;}
.custom-select {height: 162px !important; font-size: 14px !important;}
.row-input label{margin-bottom: 0 !important;}
.build-details, .form-control{font-size: 14px !important;}
.set-btn{padding: 5px 10px;}
.set-btn.active{background: #9bd3fa;}
.field-div{border: 1px solid #ccc;padding: 3px 10px;width: 470px;margin-bottom: 5px;position: relative;}
.parent-header{}
.builder-header-1{margin-left: 50px;}
.builder-header-2{margin-left: 100px;}
.builder-header-3{margin-left: 150px;}
.field-div.question{margin-left: 200px;}
.page-builder{overflow-y: scroll; height: 540px;margin-top: 20px;clear: both;}
.bottom-btn{margin-top: 5px;margin-right: 20px;}
.top-right-btn{text-align: right; margin-bottom: 20px;margin-right: 110px;height: 20px;}
.loaded-file-name{position: absolute;
    top: 30px;
    font-size: 20px;
    font-weight: 500;
    left: 324px;
}
.required{color: red;}
.not-include{display: none !important;}
.include-btn{width: 185px;float: left;margin-bottom: 20px;}
.select-delegate-filter{width: 282px;float: right;margin-bottom: 0px;margin-right: 20px;}
.filter-delegate {width: 160px !important;float: right;}
.select-attention-filter{width: 268px;float: right;margin-bottom: -10px;margin-right: 20px;}
.select-delegate-filter b,.select-attention-filter b{vertical-align: -webkit-baseline-middle;}
.filter-attention{ width: 140px !important;float: right;}
/* .clear-temp-top1{position: absolute;right: 295px;top: 30px;font-size: 12px !important;}

.clear-temp-top{position: absolute;right: 140px;top: 30px;font-size: 12px !important;}
.clear-temp-top2{position: absolute;right: 25px;top: 30px;font-size: 12px !important;}
.clear-temp-top3{position: absolute;right: 422px;top: 30px;font-size: 12px !important;} */
.clear-temp-top{font-size: 12px !important; margin-right: 2px;}
.build-page-title{position: relative;}
.build-page-title .page-title{ margin-bottom: 10px;text-align: left;}
.drag-section li{list-style: none !important;cursor: pointer;}
.field-div:hover{background: #a6d3f3;}
.build-page-title .page-title{width: 320px;float: left;}
/* .btn-delete{float: right; position: absolute;right: 5px;padding: 0px !important;}
.btn-edit{float: right; position: absolute;right: 25px;padding: 0px !important;}
.btn-copy{float: right; position: absolute;right: 50px;padding: 0px !important;} */
.code-dialog{padding: 0px !important;margin-right: 5px;}
.showcase {
    position: relative;
    top: 1px;
	cursor: pointer;
    display: inline-block;
    width: 18px;
    height: 11px;
    opacity: .25;
    margin-right: 20px;
    cursor: row-resize;
    background: -webkit-linear-gradient(top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
    background: -webkit-gradient(
linear,left top, left bottom,from(#000),color-stop(20%, #000),color-stop(0, #fff),color-stop(40%, #fff),color-stop(0, #000),color-stop(60%, #000),color-stop(0, #fff),color-stop(80%, #fff),color-stop(0, #000),to(#000));
    background: -webkit-linear-gradient(
top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
    background: linear-gradient(
180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
}

/*.btn-update-data{position: absolute; margin-bottom: 20px;bottom: -10px;right: 75px;}
 .btn-undo-data{position: absolute; margin-bottom: 20px;bottom: -10px;right: 160px;} */
.rdw-image-modal{left: -113px !important;top:-43px !important; }

#inlineVideo iframe	{ width: 380px !important; height: 275px !important;}

.selected {
    border: 2px solid #24b114;
    box-shadow: 0 12px 22px 1px #333;
}
.field-edit-section .form-group {
    margin-bottom: 10px !important;
}
.company-select{float: left; width: 245px !important;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.column-input input {
    display: inline-block;
    width: 220px;
    margin-bottom: 5px;
}

.row-input input {
    display: inline-block;
    width: 274px;
    margin-bottom: 5px;
}
.custom-validation {
    width: 140px!important;
    display: inline-block!important;
    margin-left: 10px;
}
.weight-txt{width: 240px !important;}
.weight-val{width: 110px !important;}

.small-width{width: 75px !important;}

.mds-select{float: right;width: 170px !important; margin-right: 95px;}
.build-edit-section .mds-div{height: 40px;}
.mds-div label{margin-top: 8px;}
.filter-mds{ width: 190px !important; float: right;}
.select-mds-filter{margin-top: 15px;}
.sheet-name-i {
    width: 50%;
    float: left;
}
.sheet-row-count{width: 10%;display: inline-block;}
.sheet-last-update{width: 20%;display: inline-block;}

.download-icon{float: right;}
.total-field{margin-right: 45px;}
.last-field-update{margin-right: 138px;}
.api-icon img {
    width: 30px;
    height: 28px;
}
.row-input-date input {background-color: #fff;padding: 10px 18px;}
.status-tracker-div{font-size: 12px;padding: 10px;height: 648px;overflow: scroll;}
.question.not-response{display: none !important;}
.download-icon i{margin-left: 40px;cursor: pointer;}
.field-set-icon i{margin-left: 26px !important;cursor: pointer;}
.load-mds-button{margin-top: 10px;}
.load-mds-button2{margin-top: 10px;}
.btn-undo-data{display:none;}
.top-action-icon{display: inline-block;width: 100%;text-align: right;}
.top-action-icon i{float: right; width: 20px;margin-right: 196px;}
.grid-dropdown label{width: 100%;}
.grid-dropdown .form-control{width: 265px;}
.grid-dropdown .same-for-all{width: 20px;}
.page-builder .input-title{width: 210px;display: inline-block;}
.page-builder .fs-input-title{width: 290px;display: inline-block;}
.show-output-txt{text-align: right;margin-top: 5px;}
.btn-eye-fset{float: right;}
.btn-analze-report{display: inline-block;}
.btn-analze{margin-left: 40px;}
.btn-report{margin-left: 5px;}
.btn-report-pdf{margin-left: 5px;}
.btn-main-video{margin-left: 5px;}
.btn-report-pdf i{font-size: 36px;color: red;}
.api-video iframe{border: none !important;}
#kioskUser{z-index: 10000 !important}
.add-opt-q2{margin-top: 5px;}
.save-output-response{margin-left: 10px;display: inline-block;float: right; margin-right: 20px;}
.save-output-send{margin-left: 10px;display: inline-block;float: right;}
.edit-btns {
    display: inline;
    margin-right: 10px;
}

.fs-btn{float: right;}

.edit-btns1 {
    display: inline;
}
.code-dialog2{
	border-color: #ccc;
	width: 130px;
	height: 25px; 
}
.need-icon{width: 40px;margin-top: -6px;}
.needNote{padding: 0px 0px 0px 5px !important}
.error .answer {border: 1px solid #f50606;}
.add-delegate{display:none;}
.show-output-text{width: 200px;float: left;margin-top: 5px;}
.launch-pad{width:100%}
.v-min{width: 108px !important;margin-right: 4px;}
.v-max{width: 108px !important;}
.bottom-btn .btn{margin-right: 2px;float: right;}
.comments{padding: 20px;height: 580px;overflow: auto;clear: both;}
.TestForm{height: 568px !important;}
.childMain .question{ padding-bottom: 20px;}
.add-new-pos-2{float: right;margin-right: 8px;margin-top: 8px;}
.mui-background {
    position: relative;
    outline: 0px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 24px;
    background-color: rgb(231, 235, 240);
}
.input-text{margin-bottom: 15px;}
.cus-radio-btn{height: 14px;width: 14px;}
.MuiOutlinedInput-inputMarginDense {
    padding-top: 7.5px !important;
    padding-bottom: 7.5px !important;
}
.MuiPaper-root .MuiCheckbox-root{padding: 5px !important;}
.css-1ibwkvh-MuiGrid-root>.MuiGrid-item {
    padding-top: 15px !important;
}
.completed-forms h6{font-size: 18px;margin-bottom: 20px;}
.completed-forms{margin-top: 60px;border: 1px solid #ccc;padding: 20px 10px;}
.launchpad-section .MuiFormControl-root{width: 245px;}
.MuiSlider-sizeMedium{width: 240px !important;height: 8px !important;}
.mui-slider{width: inherit !important;}
.mui-slider span{display: table-cell !important;}
.MuiTextField-root{width: 100% !important;}
.ver-checkbox{margin-left: 5px;}
.ver-radio{margin-left: 15px;}
.answer-q5{float: left;margin-top: -1px;margin-right: 5px;}
/* .question-q5{margin-top: 5px;} */
.MuiBox-root textarea{border-color: #ccc;outline: inherit !important;}

.MuiOutlinedInput-notchedOutline {
    border-color: #ccc !important;
}
.sig-horizontal{width: 65%;}
.sig-save-btn{padding: 7px 5px !important; float: left;}
.sig-date-field{float: right; width: 60% !important;}
.file-upload-image{width: 100%;}
.answer iframe{width:100%;height:420px;}
.smart-btn{
    width: 14px;
    height: 14px;
    margin: 5px;
}
.apiPdf-table {
    max-height: 220px;
    overflow: auto;
    margin-bottom: 10px;
    background-color: #fff;
}
.apiPdf-list .fa-file-pdf {
    color: #00f;
    font-size: 25px;
    float: right;
}
.pdf-api-dev embed{height: 420px;}

.answer .row{margin-bottom: 5px;}
.cal-res-box {
    text-align: center;
    border: 1px solid;
    padding: 20px;
    margin-left: 15px;
}
.boxbegin-div {
    border: 3px solid #ccc;
    border-bottom: none;
}
.in-box {
    border-left: 3px solid #ccc;
    border-right: 3px solid #ccc;
    padding: 5px 20px;
}
.boxend-div, .in-box-last {
    border: 3px solid #ccc;
    border-top: none;
}
.auto-tag {
    color: #00008b;
    font-weight: 700;
}
.btn-note {
    padding: 5px 10px !important;
    font-size: 1rem!important;
	float: right;
}
.txt-additional{font-size: 13px;font-style: italic;}
.modal-dialog {
    max-width: 590px !important;
}

.modal-dialog-2 {
    max-width: 690px !important;
}
.grid-option-input{width: 100% !important;}
.bottom-action-icon{width: 50%;
    margin: 0 auto;
    margin-right: 140px;
    padding: 10px 0px 25px 0px;
	font-weight: 500;
}
.launch-btn{margin-left: 8px !important;}
.css-69324s button {
    margin: 8px 0 ;
}
.view-launch-pdf a:hover {
    text-decoration: none;
}
.launch-pdf span {
    font-size: 18px;
    vertical-align: super;
    border: 2px solid #cbd2ff;
    padding: 5px;
    border-radius: 2px;
}
.launch-pdf i {
    color: #de4040;
    font-size: 38px;
}
.instance-update{margin-top:10px;}
.instance-manager{width: 50%;margin: auto;}
.ins-title{text-align: center; padding: 20px; font-size: 24px;}
.associat-select{height: 60px !important;}
.load-mds-filter{ text-align: center; margin-bottom: 10px;}
.input-check-list{width: 50%; float: left;}
.ver-checkbox-2{clear: both;}
.ogtags-select{height: 160px !important;}
.launch-table-div{background-color: #fff;min-height: 80px; max-height: 200px;overflow: scroll;padding: 5px;}
.launch-table-div table td{padding: 5px;}
.filter-form{width: 160px !important;float: right;margin-top: -5px;}
.find-status-filter{width: 254px;float: right;margin-bottom: 10px;margin-right: 0px;}
.find-facility-filter{width: 205px;float: right;margin-bottom: 10px;margin-right: 10px;}
.find-form-filter{width: 232px;float: right;margin-bottom: 10px;margin-right: 10px;}
.find-role-filter{width: 192px;float: right;margin-bottom: 10px;margin-right: 10px;}
.find-person-filter{width: 204px;float: right;margin-bottom: 10px;margin-right: 10px;}
.find-status-filter b,.find-facility-filter b,.find-form-filter b,.find-role-filter b,.find-person-filter b{vertical-align: -webkit-baseline-middle;}
.btn-update-status{float: left;margin-top: 10px;}
#showFormContent{visibility: hidden;}
.instance-version{position: absolute; bottom: -10px;font-size: 14px;}
.tracker-modal{font-size: 16px;}
.answer .MuiSvgIcon-root{display: block !important;}
/*.css-i4bv87-MuiSvgIcon-root{color: #ccc !important;}*/
.fa-flag{color:red;cursor: pointer;}
.multis-div{margin-bottom: 5px;}
.multiss-div{margin-bottom: 5px;}
.multiss-div select{height: 125px !important;}
.tracker-table.table td,.tracker-table.table th{padding: 3px 10px !important;}
.tracker-table button{padding: 0px 10px !important;height: 20px;font-size: 14px;}
.hide-row{display: none !important;}
.k-sort{cursor: pointer;}
.status-list{padding: 10px;width: 50%;margin: auto;}
.status-table.table td,.status-table.table th{padding: 3px 10px !important;}
.status-table-div{height: 470px;overflow: scroll;}
.status-tracker-top{ display: inline-block; text-align: center; width: 100%; margin-bottom: 15px; margin-top: 10px;}
.status-tracker-top label{display: inline-block; margin-right: 15px;}
.status-tracker-top input{display: inline-block; width: 270px; margin-right: 15px;}
.status-tracker-top button{display:inline-block;} 
.edit-btns-status {display: inline;margin-right: 10px;float: right;}
.status-table-div input{vertical-align: middle;margin-right: 10px;}
.status-list h4{ text-align: center;}
.status-inner{padding: 20px 120px;}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#myImageModal{
  z-index: 1051;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.embed {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 23px;
}
.showAllSwitch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.input_inc{
    float: left;
    margin-top: 0px;
    margin-right: 2px;
}
.move-input{
    margin-right: 5px;
}
.move-bottons{
  position: fixed;
    right: 40px;
    top: 270px;
}
.move-bottons p{
  margin: 0;font-size: 25px;cursor: pointer;
}
.popup-arrow{
    height: 10px;
    width: 10px;
    position: absolute;
    background: rgb(255, 255, 255);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    margin: -5px;
    z-index: -1;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;
    top: 100%;
    left: 50%;
}
.popup-content{
    position: absolute;
    z-index: 2;
    width: auto;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(187, 187, 187);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
    padding: 5px;
    top: -80px;
    left: -24.8438px;
}
.popup-content1{
    position: absolute;
    z-index: 2;
    width: auto;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(187, 187, 187);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
    padding: 5px;
    top: 0px;
    left: 227.578px;
}
.popup-arrow1 {
    height: 10px;
    width: 10px;
    position: absolute;
    background: rgb(255, 255, 255);
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    margin: -5px;
    z-index: -1;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px;
    top: 19px;
    left: 0%;
  }
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.embedslider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 23px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.showAllSlide:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 23px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}
input:checked + .showAllSlide:before {
  -webkit-transform: translateX(11px);
  transform: translateX(11px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider.round { 
  border-radius: 34px;
}
.slider.round:before { 
  border-radius: 50%;
}
.modal-body {
    position: relative;
    overflow-y: auto;
    max-height: 400px;
    padding: 15px;
}
/* .loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
} */

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.reward-input{ width: auto; display: inline-block; margin-left: 9px;}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

